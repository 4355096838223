import * as $ from "jquery";
import * as Popper from "popper.js";
import "bootstrap";
import svg4everybody from "svg4everybody";
import objectFitImages from "object-fit-images";
import "slick-carousel";

window.$ = $;
window.jQuery = $;
window.Popper = Popper;

objectFitImages();
svg4everybody();

function updateLabel(slick, currentSlide) {
  var $currentSlide = $(slick.$slides.get(currentSlide));
  var label = $currentSlide.find("img").attr("alt");
  $(".healthy-swaps__items-label").text(label);
}

$(document).ready(function() {
  if ($(".healthy-swaps").length) {
    var $sliderItems = $(".healthy-swaps__items");

    $sliderItems.on("init", function(event, slick, currentSlide) {
      updateLabel(slick, currentSlide);
    });

    $sliderItems.on("afterChange", function(event, slick, currentSlide) {
      updateLabel(slick, currentSlide);
    });

    $sliderItems.slick({
      centerMode: true,
      centerPadding: "0px",
      asNavFor: ".healthy-swaps__substitutes",
      slidesToShow: 3,
      arrows: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });

    $(".healthy-swaps__substitutes").slick({
      asNavFor: ".healthy-swaps__items",
      slidesToShow: 1,
      arrows: false,
      speed: 500,
      fade: true,
      cssEase: "linear"
    });
  }

  if ($(".news-slider").length) {
    $(".news-slider").slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });
  }

  if ($(".lunchbox-slider").length) {
    $(".lunchbox-slider").slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      dots: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });
    $('.lunchbox-slider [data-toggle="popover"]').popover({
      container: "body",
      placement: "top",
      html: true,
      trigger: "focus"
    });
  }

  if ($(".recipes-slider").length) {
    $(".recipes-slider").slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      dots: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });
  }

  $("[data-target='#lunchboxModal']").on("click", function() {
    let imgSrc = $(this)
      .find("img")
      .attr("src");
    imgSrc = imgSrc.replace(/\.(jpg|jepg|png)$/, ".gif");
    const content = $(this).data("content");
    $("#lunchboxModal .modal-body p").append(content);
    $("#lunchboxModal .modal-body img").attr("src", imgSrc);
  });

  $("#lunchboxModal").on("hidden.bs.modal", function() {
    $("#lunchboxModal .modal-body p").html("");
  });
});

// Navbar
const $dropdown = $(".navbar-nav .dropdown");
const $dropdownToggle = $(".dropdown-toggle");
const $dropdownMenu = $(".dropdown-menu");
const showClass = "show";

$(window).on("load resize", function() {
  if (this.matchMedia("(min-width: 992px)").matches) {
    $dropdown.hover(
      function() {
        const $this = $(this);
        $this.addClass(showClass);
        $this.find($dropdownToggle).attr("aria-expanded", "true");
        $this.find($dropdownMenu).addClass(showClass);
      },
      function() {
        const $this = $(this);
        $this.removeClass(showClass);
        $this.find($dropdownToggle).attr("aria-expanded", "false");
        $this.find($dropdownMenu).removeClass(showClass);
      }
    );
  } else {
    $dropdown.off("mouseenter mouseleave");
  }
});
